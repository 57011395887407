import React, { useEffect, useState } from "react";
import axios from "axios";
import SearchBar from "./SearchBar/SearchBar";
import Card from "./Card/Card";
import Footer from "./Footer/Footer";
import { API_URL } from "./Services/Config";
import NavbarWithObserver from "./NavBar/NavBar";
import Config from "./Services/Config";

function Home() {
  const [cardsData, setCardsData] = useState([]);
  const [cardsDataBuy, setCardsDataBuy] = useState([]);

  const [categories, setCategories] = useState([]);
  const [filteredCards, setFilteredCards] = useState([]);
  const [filteredCardsBuy, setFilteredCardsBuy] = useState([]);

  
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchInput, setSearchInput] = useState("");
  const [priceRange, setPriceRange] = useState({ min: 0, max: 1000 });
  const [openDialog, setOpenDialog] = React.useState(false);
  const [long, setLongitude] = useState(0);
  const [lat, setLatitude] = useState(0);

  const handleSetOpenDialog = () => {
    setOpenDialog(true);
  }
  const handleSetCloseDialog = () => {
    setOpenDialog(false);
  }


  //to get the current location via public ip
  useEffect(() => {
    const fetchHomeCardDetails = async () => {
      const response = await fetch('https://ipapi.co/json/');
        const data = await response.json();
        const { latitude, longitude } = [data.latitude,data.longitude];
        setLongitude(data.longitude);
        setLatitude(data.latitude);

    }
    setLoading(true);
    fetchHomeCardDetails()
  }, []);

  useEffect(() => {
    const getAllProperties = async () => {
      try {
        console.log("longitude", long);
        console.log("latitude", lat);
        const data = await Config.getHomeCardDetails(long, lat);
        console.log("dataaaaaaaaaaaaaaa",data);
        if(data.rent){
          setCardsData(data.rent);
          setFilteredCards(data.rent);
          setCardsDataBuy([]);
          setFilteredCardsBuy([]);
        }else if(data.buy){
          setCardsDataBuy(data.buy);
          setFilteredCardsBuy(data.buy);
          setCardsData([]);
          setFilteredCards([]);
        }
        
        // setCardsData(data);
        // setFilteredCards(data);
        setLoading(false);
      } catch (exception) {
        console.error(exception);
        setLoading(false);
      }
    };

    if (long !== 0 && lat !== 0) {
      getAllProperties();
    }
  }, [long,lat]);
  
  // useEffect(() => {
  //   const fetchHomeCardDetails = async () => {
  //     navigator.geolocation.getCurrentPosition(
  //       (position) => {
  //         const { latitude, longitude } = position.coords;
  //         setLongitude(longitude);
  //         setLatitude(latitude);
  //         try {
  //           console.log("long down",longitude,"lat down ",latitude);
  //           const getAllProperties = async () => {
  //             try {
  //               const data = await Config.getHomeCardDetails(longitude,latitude);
  //               console.log("data on loading page ",data)
  //               setCardsData(data);
  //               setFilteredCards(data);
  //               setLoading(false);
  //             } catch (exception){
  //               console.error(exception);
  //               setLoading(false);
  //             }
  //           };
  //           getAllProperties();
            
  //         } catch (error) {
  //           setError(error);
  //           setLoading(false);
  //         }
  //       },
  //       (error) => {
  //         console.error(`Error getting geolocation: ${error.message}`);
  //       }
  //     );

      
  //   };

  //   fetchHomeCardDetails();
  // }, []);

  // useEffect(() => {
  //   navigator.geolocation.getCurrentPosition(
  //     (position) => {
  //       const { latitude, longitude } = position.coords;
  //       setLongitude(longitude);
  //       setLatitude(latitude);
  //       // console.log(`Latitude here : ${latitude}, Longitude here : ${longitude}`);
  //     },
  //     (error) => {
  //       console.error(`Error getting geolocation: ${error.message}`);
  //     }
  //   );
  // },[]);


  // console.log("lat",lat);
  // console.log("long",long);


  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get(
          `${API_URL}/categories`
        );
        setCategories(response.data);
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    fetchCategories();
  }, []);


  const handleSearch = async ({ location, checkIn, checkOut, guests, minPrice, maxPrice, selectedCategory, maxRange, selectedPropertyType, selectedBedroom, selectedBeds, selectedBathroom, long, lat,selectedButton }) => {

    try {
      // console.log("testing");


        
        if(checkIn == null){
          
          checkIn = "";
          checkOut = "";
        }
        

        const response = await Config.searchFunctionality(
          location,
          checkIn,
          checkOut,
          guests,
          minPrice,
          maxPrice,
          selectedCategory,
          maxRange,
          selectedPropertyType,
          selectedBedroom,
          selectedBeds,
          selectedBathroom,
          long,
          lat,
          selectedButton,
        );

        // const response = await Config.getHomeCardDetails(long,lat);

        console.log("response search button : ",response);
        
        if(response.rent){
          setCardsData(response.rent);
          setFilteredCards(response.rent);
          setCardsDataBuy([]);
          setFilteredCardsBuy([]);
        }else if(response.buy){
          setCardsDataBuy(response.buy);
          setFilteredCardsBuy(response.buy);
          setCardsData([]);
          setFilteredCards([]);
        }else if(response.all){
          setCardsData(response.all.rent);
          setFilteredCards(response.all.rent);
          setCardsDataBuy(response.all.buy);
          setFilteredCardsBuy(response.all.buy);
        }

        setLoading(false);
      } catch (error) {
        setError(error);
      }
    };


  const applyFilters = () => {

    const filteredData = cardsData.filter((data) => {
      const lowercasedDataLocation = data.location.toLowerCase().trim();
      const includesCheck = lowercasedDataLocation.includes(
        searchInput.toLowerCase()
      );
      const priceCheck =
        data.price >= parseInt(priceRange.min, 10) &&
        data.price <= parseInt(priceRange.max, 10);

      return includesCheck && priceCheck;
    });

    setFilteredCards(filteredData);
  };

  useEffect(() => {
    applyFilters();
  }, [searchInput, cardsData]);

  return (
    <div className="App">

      <NavbarWithObserver openDialog={openDialog} handleSetOpenDialog={handleSetOpenDialog} handleSetCloseDialog={handleSetCloseDialog} />

      <SearchBar onSearch={handleSearch} />

      {loading ? (
  <div>Loading...</div>
) : error ? (
  <div>Error: {error.message}</div>
) : (
  <>
    {filteredCards.length > 0 ? (
      <div className="card-grid">
        {filteredCards.map((data) => (
          <div key={data.id} style={{ marginTop: "2%" }}>
            <Card
              id={data.id}
              name={data.name}
              rent_buy={data.rent_buy}
              price={data.price}
              location={data.location}
              available_at={data.available_at}
              availability_ends_at={data.availability_ends_at}
              distance={data.distance}
              property_images={data.property_images}
            />
          </div>
        ))}
      </div>
    ) : (
      // <div>No matching properties found.</div>
      <div></div>
    )}
  </>
)}
<br/>
{loading ? (
  <div>Loading...</div>
) : error ? (
  <div>Error: {error.message}</div>
) : (
  <>
    {filteredCardsBuy.length > 0 ? (
      <div className="card-grid">
        {filteredCardsBuy.map((data) => (
          <div key={data.id} style={{ marginTop: "2%" }}>
            <Card
              id={data.id}
              name={data.name}
              rent_buy={data.rent_buy}
              price={data.price}
              location={data.location}
              available_at={data.available_at}
              availability_ends_at={data.availability_ends_at}
              distance={data.distance}
              property_images={data.property_images}
            />
          </div>
        ))}
      </div>
    ) : (
      // <div>No matching properties found.</div>
      <div></div>
    )}
  </>
)}

<br/>

<div style={{ display: 'flex', justifyContent: 'center' }}>
  <img src={`${process.env.PUBLIC_URL}/ad1.jpg`} alt="Day Image" style={{ width: '45%', height: '250px', margin: '10px' }} />
  <img src={`${process.env.PUBLIC_URL}/ad2.jpg`} alt="Day Image" style={{ width: '45%', height: '250px', margin: '10px' }} />
</div>

      <Footer />
    </div>
  );
}

export default Home;
