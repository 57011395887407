import React, { useState } from "react";
import "./Login.css";
import axios, { AxiosHeaders } from "axios";
import Button from "@mui/material/Button";
import AuthService from "../Services/AuthService";
import SnackbarComponent from "../SnackBar/SnackBar";

const Login = ({ openLoginForm , closeLogInPage }) => {
  const [email, setEmail] = useState("");


  const [password, setPassword] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("info");

  var loginResponseMessage;

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const body = {
        email: email,
        password: password,
      };

      AuthService.login(body).then(
        (response) => {
          let loginResponseMessage, severity;

          if (response.success) {
            loginResponseMessage = "Login Successful";
            severity = "success";
            setTimeout(() => {
              window.location.reload(false);
          }, 500);
          } else {
            loginResponseMessage = response.message;
            severity = "error";
          }

          setOpenSnackbar(true);
          setSnackbarMessage(loginResponseMessage);
          setSnackbarSeverity(severity);

          console.log(loginResponseMessage);
        },
        (error) => {
          console.log("Login failed", error);
        }
      );
    } catch (error) {
      console.error(error);
    }
  };

  const logout = async () => {
    if (AuthService.getToken() != null) {
      const response = await AuthService.logout();
      console.log(response.message);
    } else {
    }
  };

  return (
    <div className="login-container">
      {/* <h2 className="login-signup-txt">Welcome Back</h2> */}

      <div className="container">
      <div className="text">Login</div>
      <div className="close-btn">
        <button className="close-button" onClick={closeLogInPage}>
          x
        </button>
      </div>
    </div>
      

      <form onSubmit={handleLogin}>
        
        <div className="form-container">
        <div className="form-group">
          <label htmlFor="email" className="login-label">
            Email *
          </label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            className="login-input"
            placeholder="E-mail"
          />
        </div>
        <div className="form-group">
          <label htmlFor="password" className="login-label">
            Password *
          </label>
          <input
            type="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            className="login-input"
            placeholder="Password"
          />
        </div>

        <Button type="submit" variant="container" className="login-btn">
          Login
        </Button>

        <SnackbarComponent
          open={openSnackbar}
          setOpen={setOpenSnackbar}
          message={snackbarMessage}
          severity={snackbarSeverity}
        />
          <br/>
        <div style={{display:'flex',alignItems:'center'}}>
          <label style={{marginRight:'5px'}} >Don't have an account?{" "}</label>
          <a href
            onClick={
              openLoginForm}
            style={{cursor:'pointer'}}
          >
            Signup
          </a>
        </div>



        </div>

        
      </form>
      {/* <button onClick={logout}>Logout</button> */}
    </div>
  );
};

export default Login;
