import React, { useState } from 'react';
import './Signup.css'; // Import your stylesheet
import { Modal, Box } from '@mui/material';
import AuthService from '../Services/AuthService';
import SnackbarComponent from "../SnackBar/SnackBar";

const Signup = ({ closeSignupAndOverlay, openLoginForm }) => {

  // console.log("sign up")

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("info");

  // const handleSignup = (e) => {
  //   e.preventDefault();
  //   // Implement your signup logic here
  //   console.log('Signup button clicked!');
  //   console.log('Username:', username);
  //   console.log('Email:', email);
  //   console.log('Password:', password);

  const hanldePhoneNumber = (event) => {
    let inputValue = event.target.value;

    if (inputValue === "") {
      setPhoneNumber("");
    } else {
      const numericValue = inputValue.replace(/[^0-9]/g, "");
      // const parsedValue = parseInt(inputValue, 10);
      if (!isNaN(parseInt(numericValue)) && numericValue.length > 0) {
        
        setPhoneNumber(inputValue);
      }
    }
  };

  useState(() => {
    setFirstName('');
    setLastName('');
    setEmail('');
    setPhoneNumber('');
    setPassword('');
  });



  //   // Add your signup API call or other logic here
  // };


  const handleSignup = async (e) => {
    e.preventDefault();
    try {
      const body = {
        name : firstName,
        last_name : lastName,
        phone_number: phoneNumber,
        email: email,
        password: password,
      };
      // console.log("body",body);
      AuthService.signup(body).then(
        (response) => {
          // console.log(response);
          let signUpResponseMessage, severity;
          // console.log(response);
          if (response.success) {
            signUpResponseMessage = "Account Created Successfully";
            severity = "success";
            setTimeout(() => {
              window.location.reload(false);
          }, 500);
          } else {
            signUpResponseMessage = response.message;
            severity = "error";
          }

          setOpenSnackbar(true);
          setSnackbarMessage(signUpResponseMessage);
          setSnackbarSeverity(severity);

          // console.log(signUpResponseMessage);
        },
        (error) => {
          console.log("Login failed", error);
        }
      );
    } catch (error) {
      console.error(error);
    }
  };

  return (

    <div className="signup-popup">
  <div className="close-btn">
    <button className="close-button" onClick={closeSignupAndOverlay}>
      x
    </button>
  </div>
  <div className="textSignUp">Sign Up</div>

  <form onSubmit={handleSignup} className="form-container" style={{overflowY:'auto'}}>

       <div className="form-group" style={{marginTop:'30px'}}>
           <label htmlFor="firstname" className="signup-label">
             First Name *
           </label>
           <input
            type="text"
            id="firstname"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            className="signup-input"
            placeholder="Max length: 20 char"
            required
            maxLength={20}
          />
        </div>

        <div className="form-group">
          <label htmlFor="lastname" className="signup-label">
            Last Name *
          </label>
          <input
            type="text"
            id="lastname"
            required
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            className="signup-input"
            placeholder="Max length : 20 char"
            maxLength={20}
          />
        </div>


        <div className="form-group">
          <label htmlFor="email" className="signup-label">
            Email *
          </label>
          <input
            type="email"
            id="email"
            required
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="signup-input"
            placeholder="Max length : 30 char"
            maxLength={30}
          />
        </div>

        <div className="form-group">
          <label htmlFor="phoneNumber" className="signup-label">
            Phone Number *
          </label>
          <input
            type="tel"
            id="phoneNumber"
            required
            style={{ appearance: 'none', WebkitAppearance: 'none' , MozAppearance: 'textfield'}}
            value={phoneNumber}
            // onChange={(e) => setPhoneNumber(e.target.value)}
            onChange = {hanldePhoneNumber}
            className="signup-input"
            maxLength={20}
            placeholder="Max length : 20 number"
            
          />
        </div>



        <div className="form-group">
          <label htmlFor="password" className="signup-label">
            Password *
          </label>
          <input
            type="password"
            id="password"
            required
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="signup-input"
            placeholder="Max length : 20 char"
            maxLength={20}
          />
        </div>

        <button type="submit" className="signup-btn">
          Sign Up
        </button>

        <SnackbarComponent
          open={openSnackbar}
          setOpen={setOpenSnackbar}
          message={snackbarMessage}
          severity={snackbarSeverity}
        />

      <p className="already">
        Already have an account? <a href style={{cursor:'pointer'}} onClick={openLoginForm}>Log in</a>
      </p>

    <SnackbarComponent
      open={openSnackbar}
      setOpen={setOpenSnackbar}
      message={snackbarMessage}
      severity={snackbarSeverity}
    />

  </form>
</div>


    // <div className="signup-form">
    //   {/* <h2 className="login-signup-txt">Sign Up</h2> */}

    //   <div className="container">
    //   <div className="textSignUp">Sign Up</div>
    //   <div className="close-btn">
    //     <button className="close-button" onClick={closeSignupAndOverlay}>
    //       x
    //     </button>
    //   </div>
    // </div>

    //   <form onSubmit={handleSignup} className="form-container" style={{overflowY:'auto',marginBottom:'20px'}}>

    //     <div className="form-group">
    //       <label htmlFor="firstname" className="signup-label">
    //         First Name *
    //       </label>
    //       <input
    //         type="text"
    //         id="firstname"
    //         value={firstName}
    //         onChange={(e) => setFirstName(e.target.value)}
    //         className="signup-input"
    //         placeholder="Max length: 20 char"
    //         required
    //         maxLength={20}
    //       />
    //     </div>

    //     <div className="form-group">
    //       <label htmlFor="lastname" className="signup-label">
    //         Last Name *
    //       </label>
    //       <input
    //         type="text"
    //         id="lastname"
    //         required
    //         value={lastName}
    //         onChange={(e) => setLastName(e.target.value)}
    //         className="signup-input"
    //         placeholder="Max length : 20 char"
    //         maxLength={20}
    //       />
    //     </div>


    //     <div className="form-group">
    //       <label htmlFor="email" className="signup-label">
    //         Email *
    //       </label>
    //       <input
    //         type="email"
    //         id="email"
    //         required
    //         value={email}
    //         onChange={(e) => setEmail(e.target.value)}
    //         className="signup-input"
    //         placeholder="Max length : 30 char"
    //         maxLength={30}
    //       />
    //     </div>

    //     <div className="form-group">
    //       <label htmlFor="phoneNumber" className="signup-label">
    //         Phone Number *
    //       </label>
    //       <input
    //         type="tel"
    //         id="phoneNumber"
    //         required
    //         style={{ appearance: 'none', WebkitAppearance: 'none' , MozAppearance: 'textfield'}}
    //         value={phoneNumber}
    //         // onChange={(e) => setPhoneNumber(e.target.value)}
    //         onChange = {hanldePhoneNumber}
    //         className="signup-input"
    //         maxLength={20}
    //         placeholder="Max length : 20 number"
            
    //       />
    //     </div>



    //     <div className="form-group">
    //       <label htmlFor="password" className="signup-label">
    //         Password *
    //       </label>
    //       <input
    //         type="password"
    //         id="password"
    //         required
    //         value={password}
    //         onChange={(e) => setPassword(e.target.value)}
    //         className="signup-input"
    //         placeholder="Max length : 20 char"
    //         maxLength={20}
    //       />
    //     </div>

    //     <button type="submit" className="signup-btn">
    //       Sign Up
    //     </button>

    //     <SnackbarComponent
    //       open={openSnackbar}
    //       setOpen={setOpenSnackbar}
    //       message={snackbarMessage}
    //       severity={snackbarSeverity}
    //     />

    //   <p className="already">
    //     Already have an account? <a href style={{cursor:'pointer'}} onClick={openLoginForm}>Log in</a>
    //   </p>


    //   </form>


      
    // </div>
  );
};

export default Signup;
