/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef, createContext, useContext, useDebugValue } from "react";
import "./NavBar.css";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import NotificationsIcon from "@mui/icons-material/Notifications";
import PersonIcon from "@mui/icons-material/Person";
import IconButton from "@mui/material/IconButton";
import { useAuth } from "../AuthContext";
import LogoutIcon from "@mui/icons-material/Logout";
import CardTravelIcon from '@mui/icons-material/CardTravel';
import AccountBoxOutlinedIcon from '@mui/icons-material/AccountBoxOutlined';
import { useNavigate } from "react-router-dom";
import Popover from "@mui/material/Popover";
import LoginPopup from "../LoginSignup/LoginPopup";
import SignupPopup from "../LoginSignup/SignupPopup";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MenuIcon from '@mui/icons-material/Menu';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import "react-chat-elements/dist/main.css"
import { MessageBox } from "react-chat-elements";
import { ChatList } from "react-chat-elements";
import Badge from '@mui/material/Badge';
import CloseIcon from '@mui/icons-material/Close';
import { List, ListItem, ListItemAvatar, Avatar, ListItemText, Divider } from '@material-ui/core';
import Grid from "@material-ui/core/Grid";
import { InputAdornment, Input, Paper, ListItemIcon } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import Config, { IMAGES_URL } from "../Services/Config";
import TextField from "@mui/material/TextField";
import SendIcon from '@mui/icons-material/Send';
import QuestionLoginPopup from "../LoginSignup/QuestionLoginPopup";
import SignOutPopup from "../LoginSignup/SignOutPopup";
import { Accessibility, HomeIcon, LogInIcon } from "lucide-react";
import { AddHome, BusinessCenter } from "@mui/icons-material";

const ObserverContext = createContext();

function ObserverProvider({ children }) {
  const observers = useRef(new Set());

  const addObserver = (observer) => {
    observers.current.add(observer);
  };

  const removeObserver = (observer) => {
    observers.current.delete(observer);
  };

  const notifyObservers = (data) => {
    observers.current.forEach((observer) => observer(data));
  };

  return (
    <ObserverContext.Provider value={{ addObserver, removeObserver, notifyObservers }}>
      {children}
    </ObserverContext.Provider>
  );
}

function useObserver() {
  return useContext(ObserverContext);
}
export { ObserverProvider, useObserver };

function Navbar({ openDialog, handleSetOpenDialog, handleSetCloseDialog }) {
  const [isScrolled, setIsScrolled] = useState(false);
  const { addObserver, removeObserver, notifyObservers } = useObserver();
  const [loginOpen, setLoginOpen] = useState(false);
  const [questionLogin, setQuestionLogin] = useState(false);
  const [questionSignOut, setQuestionSignOut] = useState(false);
  const [signupOpen, setSignupOpen] = useState(false);
  const [backgroundAnimated, setBackgroundAnimated] = useState(false);
  const { isAuthenticated, logout, userDetails } = useAuth();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const navigate = useNavigate();
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState('xl');
  const [AllChats, setAllChats] = useState([]);
  const [AllMsgs, setAllMsgs] = useState([]);
  const [user_id, setUserId] = useState();
  const [usernameLR, setUsernameSend] = useState([]);
  const [message, setMessage] = useState('');
  const chatContainerRef = useRef(null);
  const lastMessage = AllMsgs && AllMsgs.length > 0 ? AllMsgs[0] : null;
  const isCurrentUserSender = lastMessage && lastMessage.user_id === user_id;
  const [allLastMessages, setAllLastMessages] = useState({});

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }
  }, [AllMsgs]);

  useEffect(() => {
    const AllChatsFunction = async () => {
      try {
        const data = await Config.getallchats(1);
        setAllChats(data);

        if (data.length > 0) {
          const firstChat = data[0];
          handleListItemClick(firstChat.user.id, firstChat.user.name,firstChat);
        }

        const lastMessagesForChats = {};
        for (const chat of data) {
          const messages = await Config.getallmessages(chat.user.id);
          lastMessagesForChats[chat.user.id] = messages.length > 0 ? messages[0] : null;
        }

        setAllLastMessages(lastMessagesForChats || {});
      } catch (error) {
        console.log(error);
      }
    };

    AllChatsFunction();
  }, []);

  const switchToSignup = () => {
    handleLoginClose();
    handleSignupOpen();
  };

  const switchToLogin = () => {
    handleSignupClose();
    handleLoginOpen();
  };

  const handleLogout = () => {
    logout();
    navigate("/");
    window.location.reload();
  };

  const handleQuestionLoginClose = () => {
    setQuestionLogin(false);
  };

  const handleQuestionSignOutOpen = () => {
    setQuestionSignOut(true);
  }

  const handleQuestionSignOutClose = () => {
    setQuestionSignOut(false);
  }

  const handleQuestionLoginToLoginPopup = () => {
    setQuestionLogin(false);
    handleLoginOpen();
  }

  const handleLoginOpen = () => {
    setLoginOpen(true);
    setSignupOpen(false);
  };

  const handleLoginClose = () => {
    setLoginOpen(false);
    setBackgroundAnimated(false);
    // console.log("close login");
  };

  const handleSignupOpen = () => {
    setLoginOpen(false);
    setSignupOpen(true);
  };

  const handleSignupClose = () => {
    setSignupOpen(false);
    setBackgroundAnimated(true);
  };

  const handleListItemClick = async (userId, usernamee,data) => {
    setAllMsgs([]);
    setUserId(userId);
    setUsernameSend([usernamee, "me"]);
    data.unread_count = 0;
    const res = await Config.updateUnreadMessaged(userId);

    loadMessagesAndUpdateLastMessage(userId, usernamee);
    try {
      const data = await Config.getallmessages(userId);
      setAllMsgs(data);
    } catch (error) {
      console.log(error);
    }
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  useEffect(() => {
    const handleResize = () => {
      if (open) {
        handleClose();
      }
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [open]);

  useEffect(() => {
    let prevScrollY = window.scrollY;
    let timeout;

    const handleScroll = () => {
      clearTimeout(timeout);

      timeout = setTimeout(() => {
        const currentScrollY = window.scrollY;
        if (currentScrollY > prevScrollY && currentScrollY > 100) {
          setIsScrolled(true);
        } else if (currentScrollY < prevScrollY) {
          setIsScrolled(false);
        }
        prevScrollY = currentScrollY;
      }, 15);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  function handleBecomeOwner() {
    let token = localStorage.getItem('token');
    console.log(token)
    if (token !== null) {
      navigate('/become-an-owner');
    } else {
      // setLoginOpen(true);
      setQuestionLogin(true);
    }
  }

  const [anchorElMenu, setAnchorElMenu] = React.useState(null);
  const openMenu = Boolean(anchorElMenu);

  useEffect(() => {
    const handleResize = () => {
      if (openMenu) {
        handleCloseMenu();
      }
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [openMenu]);

  const handleClickMenu = (event) => {
    setAnchorElMenu(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorElMenu(null);
  };

  const handleClickOpenDialog = () => {
    handleSetOpenDialog(true);
  };

  const handleCloseDialog = () => {
    handleSetCloseDialog(false);
  };

const handleSendMessage = async (e) => {
  e.preventDefault();

  try {
    const data = await Config.send_msg(message, user_id);

    setAllMsgs((prevMsgs) => {
      const updatedMessages = [...prevMsgs];
      const chatIndex = updatedMessages.findIndex((msg) => msg.user_id === user_id);

      if (chatIndex !== -1) {
        updatedMessages[chatIndex] = data;
      } else {
        updatedMessages.push(data);
      }

      return updatedMessages;
    });

    const emptyMessage = "";
    setMessage(emptyMessage);

    notifyObservers({
      user_id: user_id,
      body: message,
      created_at: new Date(),
      username: userDetails?.username || 'Me',
    });

  } catch (error) {
    console.error(error);
  }
};


  const scrollToBottom = () => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }
  };
  
  const loadMessagesAndUpdateLastMessage = async (userId, usernamee) => {
    try {
      const messages = await Config.getallmessages(userId);
      setAllMsgs(messages);

      setAllLastMessages((prevLastMessages) => ({
        ...prevLastMessages,
        [userId]: messages.length > 0 ? messages[0] : null,
      }));

    } catch (error) {
      console.error(error);
    }
  };

  // useEffect(() => {
  //   const fetchNewMessages = async () => {
  //     try {
  //       const updatedMsgs = await Config.getallmessages(user_id);
  //       if (!areArraysEqual(updatedMsgs, AllMsgs)) {
  //         setAllMsgs(updatedMsgs);
  //         scrollToBottom();
  //       }
  //     } catch (error) {
  //       console.error(error);
  //     }
  //   };

  //   const pollingInterval = 10000;
  //   const intervalId = setInterval(fetchNewMessages, pollingInterval);

  //   return () => clearInterval(intervalId);
  // }, [user_id, AllMsgs, setAllMsgs]);

  const areArraysEqual = (arr1, arr2) => {
    return JSON.stringify(arr1) === JSON.stringify(arr2);
  };

  const handleOpenChatDialog = () => {
    if (isAuthenticated) {
      if (user_id) {
        //handleListItemClick(user_id, usernameLR[0]);
        const lastMessageForChat = allLastMessages[user_id];
        if (lastMessageForChat) {
          notifyObservers(lastMessageForChat);
        }
      }
      handleSetOpenDialog(true);
    } else {
      handleLoginOpen();
    }
  };

  useEffect(() => {
    const handleObserverUpdate = (data) => {
      setAllLastMessages((prevUserMessages) => {
        const updatedUserMessages = { ...prevUserMessages };

        if (updatedUserMessages[data.user_id]) {
          updatedUserMessages[data.user_id] = data;

          updatedUserMessages[data.user_id].hasNewMessage = true;
        } else {
          updatedUserMessages[data.user_id] = { ...data, hasNewMessage: true };
        }

        return updatedUserMessages;
      });

      setAllMsgs((prevMsgs) => {
        const updatedMessages = [...prevMsgs];
        const chatIndex = updatedMessages.findIndex((msg) => msg.user_id === data.user_id);

        if (chatIndex !== -1) {
          updatedMessages[chatIndex] = data;
        } else {
          updatedMessages.push(data);
        }

        return updatedMessages;
      });
    };

    addObserver(handleObserverUpdate);

    return () => {
      removeObserver(handleObserverUpdate);
    };
  }, [addObserver, removeObserver, setAllLastMessages, setAllMsgs]);

  return (
    <nav className={`navbar ${isScrolled ? "navbar-scrolled" : ""}`}>
      <div className="logo">
        <a href="/">
          <img
            src="/LOGO-divinests-red.png"
            alt="logo"
          />
        </a>
        {/* <div style={{color: "black", fontWeight: "500", textShadow: "-1px 2px 2px rgba(0, 0, 0, 0.3)", fontFamily: "Helvetica, sans-serif", marginTop: 'auto', marginBottom: '1rem'}} ><a href="/" style={{textDecoration:'none',color:'inherit'}}><label style={{cursor:'pointer'}}>HAMROGHAR <br /> REAL ESTATE</label></a></div> */}
      </div>

      <ul className="nav-links">

        {windowWidth > 700 && (
          <>
            <div className="nav-btns">
              <Button
                variant="outlined"
                onMouseOver={(e) =>
                  (e.currentTarget.style.backgroundColor = "#bb0129")
                }
                onMouseOut={(e) =>
                  (e.currentTarget.style.backgroundColor = "")
                }
                startIcon={<NotificationsIcon fontSize="small" style={{}} />}
                className="nav-btn"
                onClick={handleOpenChatDialog}
              >
                Notifications
              </Button>
              <Dialog
                open={openDialog}
                fullWidth={fullWidth}
                maxWidth={maxWidth}
                onClose={handleCloseDialog}
                PaperComponent={(props) => (
                  <Paper {...props} style={{ height: '100rem', width: '100%' }} />
                )}
              >
                <DialogTitle variant="h4" style={{ display: 'flex', justifyContent: 'space-between' }}>
                  Chats with {usernameLR[0]}
                  <IconButton
                    aria-label="close"
                    onClick={handleCloseDialog}
                    sx={{
                      position: 'absolute',
                      borderRadius: "100%",
                      color: (theme) => theme.palette.grey[500],
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                </DialogTitle>

                <DialogContent style={{ backgroundColor: "rgb(248 248 249)", overflow: "hidden" }}>

                  <Grid container style={{ height: '100vh' }}>
                    <Grid item lg={3}>
                      <List style={{ borderRight: "1px solid #d7d7d7", height: '100%' }}>
                        <ListItem>
                          <Paper
                            component="form"
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              width: '300px',
                              margin: '0 auto',
                              marginBottom: '16px'
                            }}
                          >
                            <Input
                              placeholder="Search"
                              fullWidth
                              disableUnderline
                              sx={{
                                marginLeft: '8px',
                                paddingRight: '16px',
                                '&::placeholder': {
                                  textAlign: 'left',
                                },
                              }}
                              endAdornment={
                                <InputAdornment position="end">
                                  <IconButton aria-label="search">
                                    <SearchIcon />
                                  </IconButton>
                                </InputAdornment>
                              }
                            />
                          </Paper>
                        </ListItem>
                        <Grid item>
                          <div style={{ height: 'calc(100vh - 210px)', overflowY: 'auto' }}>
                            
                            {AllChats && AllChats.length > 0 ? (
                              
                              AllChats.map((data, index) => {
                                
                                const lastMessageForChat = allLastMessages[data.user.id];
                                const hasNewMessage = lastMessageForChat && lastMessageForChat.hasNewMessage;
                                
                                return (
                                  <React.Fragment key={index}>
                                    <ListItem
                                      button
                                      onClick={() => handleListItemClick(data.user.id, data.user.name,data)}
                                      className={hasNewMessage ? 'new-message' : ''}
                                    >
                                      <ListItemAvatar>
                                      
                                        <Avatar alt={data.user.name} src={`${IMAGES_URL}${data.user.profile_pic}`} />
                                      </ListItemAvatar>
                                      <ListItemText
                                        primary={data.user.name}
                                        secondary={
                                          lastMessageForChat
                                            ? lastMessageForChat.body
                                            : "No messages"
                                        }
                                      />
                                      <Badge
                                          badgeContent={data.unread_count}
                                          color="success"
                                          overlap="circular"
                                        />
                                    </ListItem>
                                    {index < AllChats.length - 1 && <Divider />}
                                  </React.Fragment>
                                );
                              })
                            ) : (
                              <div>No chat found.</div>
                            )}

                          </div>
                        </Grid>
                      </List>
                    </Grid>

                    <Grid item lg={9} style={{ position: "sticky" }}>
                      <div ref={chatContainerRef} 
                      style={{ height: 'calc(100vh - 210px)', overflowY: 'auto' }}>
                        {[...AllMsgs].reverse().map((data, index, array) => {
                          const messageDate = new Date(data.created_at);
                          const options = { weekday: 'short', month: 'numeric', day: 'numeric', year: 'numeric' };
                          const formattedDate = messageDate.toLocaleDateString(undefined, options);

                          const shouldDisplayDate = index === 0 || (index > 0 && messageDate.toDateString() !== new Date(array[index - 1].created_at).toDateString());

                          return (
                            <React.Fragment key={index}>
                              {shouldDisplayDate && (
                                <div style={{ textAlign: 'center', marginTop: '10px', color: '#888' }}>
                                  {formattedDate}
                                </div>
                              )}
                              <div>
                                <MessageBox
                                  position={data.user_id === user_id ? "left" : "right"}
                                  type={"text"}
                                  title={data.user_id === user_id ? "" : ""}
                                  text={data.body}
                                  className={data.isNew ? 'new-message' : ''}
                                />
                                <div className="message-date" style={{ textAlign: data.user_id === user_id ? 'left' : 'right', color: '#888' }}>
                                  {messageDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
                                </div>
                              </div>
                            </React.Fragment>
                          );
                        })}
                      </div>
                    </Grid>

                    <Grid item lg={9} style={{ position: "fixed", bottom: 33, right: 50, width: '69%', height: "70px", display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: "rgb(248 248 249)" }}>
                      <TextField
                        placeholder="Type your message"
                        variant="outlined"
                        fullWidth
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        sx={{ marginLeft: "1%", width: '100%' }}
                        autoFocus
                        onFocus={(e) => {
                          const distanceToBottom = window.innerHeight - e.target.getBoundingClientRect().bottom;
                          if (distanceToBottom < 0) {
                            window.scrollTo({
                              top: window.scrollY + distanceToBottom,
                              behavior: "smooth",
                            });
                          }
                        }}
                      />
                      <SendIcon
                        color="primary"
                        style={{ cursor: 'pointer', marginLeft: "1%" }}
                        onClick={handleSendMessage}
                        type="button"
                      />
                    </Grid>
                  </Grid>
                </DialogContent>
              </Dialog>
            </div>

            <div className="nav-btns">
              {userDetails && userDetails.type === 3 ? (
                <Button
                  variant="outlined"
                  onClick={() => navigate('/owner')}
                  className="nav-btn"
                  startIcon={<BusinessCenter/>}
                >
                  Owner Page
                </Button>
              ) : (
                <Button
                  variant="outlined"
                  onClick={handleBecomeOwner}
                  className="nav-btn"
                  startIcon={<AddHome style={{height:'20px'}}/>}

                >
                  Become an Owner
                </Button>
              )}
            </div>

            {isAuthenticated ? (
              <div className="nav-btns">
                <Button
                  variant="outlined"
                  onClick={handleClick}
                  startIcon={<PersonIcon fontSize="small" style={{}} />}
                  className={`nav-btn ${open ? 'nav-btn-active' : ''}`}
                >
                  Account
                </Button>
              </div>
            ) : (
              <div className="nav-btns">
                <Button
                  variant="outlined"
                  onClick={handleLoginOpen}
                  className="nav-btn"
                  startIcon={<PersonIcon/>}
                >
                  Login
                </Button>
              </div>
            )}
          </>
        )}

        {windowWidth <= 700 && (
          <Button
            id="basic-button"
            aria-controls={open ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClickMenu}
            sx={{
              backgroundColor: '#fff',
              '&:hover': {
                backgroundColor: 'rgba(238, 238, 238, 0.5)',
              },
            }}
          >
            <MenuIcon fontSize="large" style={{ color: "#bb0129" }} />
          </Button>
        )}

        <Menu
          id="basic-menu"
          anchorEl={anchorElMenu}
          open={openMenu}
          onClose={handleCloseMenu}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
          style={{ marginLeft: "0%" }}
        >
          {windowWidth < 700 && [
            <MenuItem key="notifications" onClick={handleClickOpenDialog}>
              Notifications
            </MenuItem>,
            !isAuthenticated && (
              <MenuItem key="becomeOwner" onClick={handleBecomeOwner}>
                Become an Owner
              </MenuItem>
            ),
            !isAuthenticated ? (
              <MenuItem key="login" onClick={handleLoginOpen}>
                account
              </MenuItem>
              


            ) : (
              [
                <MenuItem key="ownerPage" onClick={() => navigate('/owner')}>
                  Owner Page
                </MenuItem>,

                
                <MenuItem key="account" onClick={handleClick}>
                  Account
                </MenuItem>
              ]
            ),
          ]}
        </Menu>

        <div className="nav-btns">
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            <Typography sx={{ p: 1, width: "105px" }}>
              <Button className="nav-btn1 nav-btn-left" onClick={() => navigate("/profile")} startIcon={<AccountBoxOutlinedIcon></AccountBoxOutlinedIcon>}>Profile</Button>
              <Button className="nav-btn1 nav-btn-left" onClick={() => navigate("/billing")} startIcon={<CardTravelIcon></CardTravelIcon>}>Billing</Button>
              {/* <Button className="nav-btn1 nav-btn-left" onClick={handleLogout} startIcon={<LogoutIcon></LogoutIcon>}>Logout</Button> */}
              <Button className="nav-btn1 nav-btn-left" onClick={handleQuestionSignOutOpen} startIcon={<LogoutIcon></LogoutIcon>}>Logout</Button>
            </Typography>
          </Popover>
        </div>
      </ul>
        

      <QuestionLoginPopup isOpen={questionLogin} onClose={handleQuestionLoginClose} onClickLogIn={handleQuestionLoginToLoginPopup}/>

      <LoginPopup isOpen={loginOpen} onClose={handleLoginClose} onSwitchToSignup={switchToSignup} />
      

      <SignupPopup isOpen={signupOpen} onClose={handleSignupClose} onSwitchToLogin={switchToLogin} />

      <SignOutPopup isOpen={questionSignOut} onClose={handleQuestionSignOutClose} onClickSignout={handleLogout}/>


    </nav>
  );
}

export default function NavbarWithObserver({ openDialog, handleSetOpenDialog, handleSetCloseDialog }) {
  return (
    <ObserverProvider openDialog={openDialog} handleSetOpenDialog={handleSetOpenDialog} handleSetCloseDialog={handleSetCloseDialog}>
      <Navbar openDialog={openDialog} handleSetOpenDialog={handleSetOpenDialog} handleSetCloseDialog={handleSetCloseDialog} />
    </ObserverProvider>
  );
}
