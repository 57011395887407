import React, { useState } from 'react';
import { GoogleMap, Marker } from '@react-google-maps/api';

const MapComponent = ({ apiKey, onLocationChange }) => {
  const [mapCenter, setMapCenter] = useState({ lat: 33.8938, lng: 35.5018 });
  const [markerPosition, setMarkerPosition] = useState(null);

  const handleMapClick = (event) => {
    const { latLng } = event;
    const lat = latLng.lat();
    const lng = latLng.lng();

    setMarkerPosition({ lat, lng });

    onLocationChange({ latitude: lat, longitude: lng });
  };

  return (
    <GoogleMap
      mapContainerStyle={{ height: '400px', width: '100%' }}
      zoom={14}
      center={mapCenter}
      onClick={handleMapClick}
    >
      {markerPosition && <Marker position={markerPosition} />}
    </GoogleMap>
  );
};

export default MapComponent;
